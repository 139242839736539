<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="设备类型">
				<el-select v-model="form.type" placeholder="请选择设备类型" @change="changeType">
					<el-option v-for=" ( item , i ) in typeList " :key="i" v-if="i != 6" :label="item.typeName" :value="item.typeCode" ></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设置功能">
			  <el-select v-model="form.funcName" placeholder="请选择设置功能">
			    <el-option v-for=" ( item , i ) in operateTypeList " :key="i" v-if="i != 6" :label="item.dictName" :value="item.funcName"></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="时间范围">
				<el-date-picker value-format="yyyy-MM-dd" v-model="date_value" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<!-- <el-row class="mg_b text_r">
      <el-button type="primary" icon="el-icon-download" @click="device_export()" style="margin-right: 10px;">导出</el-button>
    </el-row> -->
		<el-table :data="list" border style="width: 100%" v-show="listType == 1" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号"></el-table-column>
			<el-table-column prop="typeName" label="设备类型"></el-table-column>
			<el-table-column prop="result" label="结果">
				<template slot-scope="scope">
					<el-tag size="mini" disable-transitions v-for=" ( item , i ) in operateTypeList " :key="i" v-if=" item.funcName == scope.row.funcName ">
						{{ item.dictName }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="result" label="结果">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.result == null ? 'info' : ''" disable-transitions>
						{{scope.row.result == 1 ? '成功' : '失败'}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="desc" label="设置详情"></el-table-column>
			<el-table-column prop="createTime" label="创建时间"></el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination @current-change="changePage" @size-change="sizeChange" :page-sizes="[ 10 , 20 , 30 , 40]"
				:total="total" :currentPage="form.startPage" :pageSize="form.pageSize" background
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var fileDownload = require('js-file-download');
	export default {
		data() {
			return {
				loading: false,
				form: {
					deviceNo: "",
					startPage: 1,
					pageSize: 10,
					type: 1,
					startDate: "",
					endDate: "",
					funcName:""
				},
				total: 0,
				list: [],
				typeList: [],
				date_value: '',
				listType: 1,
				operateTypeList:[]
			}
		},
		created() {
			this.init_getTypeList();
			this.changeType( 1 )
		},
		mounted() {
			this.device_list();
		},
		methods: {
			init_getTypeList() {
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					} else {
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			device_list() {
				this.loading = true;
				if (this.form.type === 0) {
					this.listType = 0
				} else {
					this.listType = 1
				}
				if (this.date_value != "" && this.date_value != null && this.date_value != undefined) {
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				} else {
					this.form.startDate = "";
					this.form.endDate = "";
					// this.$message({
					// 	type: 'error',
					// 	message: "请选择时间范围"
					// });
					// return
				}
				this.$api.deviceFuncSetHistory_list(this.form).then(res => {
					if (res.code == 200) {
						this.list = res.data.list;
						for (var i = 0; i < this.list.length; i++) {
							for (var k in this.list[i]) {
								if (this.list[i][k] == null || this.list[i][k] == "") {
									this.list[i][k] = "—"
								}
							}
						}
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					} else {
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage(e) {
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange(e) {
				this.form.pageSize = e;
				this.device_list();
			},
			device_export() {
				let data = {};
				if (this.date_value != "" && this.date_value != null && this.date_value != undefined) {
					data.startDate = this.date_value[0];
					data.endDate = this.date_value[1];
				} else {
					data.startDate = "";
					data.endDate = "";
				}
				data.deviceNo = this.form.deviceNo;
				data.deviceTypeCode = this.form.deviceTypeCode;
				this.$api.deviceData_export(data).then(res => {
					fileDownload(res, "设备数据列表.xls");
				});
			},
			changeType( e ){
				this.form.funcName = ""
				this.$api.userFuncGetSelectDict( { type : e } ).then(res => {
					this.operateTypeList = res.data
					this.operateTypeList.unshift({
						dictName: "",
						funcName: "",
					})
				});
			}
		}
	}
</script>